import { defineStore } from 'pinia'
// 保存上一个跳转页面 用于登录后返回
export const useRedirectStore = defineStore('main', {
  state: () => {
    return {
      redirectPath: ''
    }
  },
  actions: {
    setRedirectPath(path: string) {
      this.redirectPath = path
    }
  }
})
