// 控制查询输入框高亮提示
import { defineStore } from 'pinia'

export const useinputHighlightStore = defineStore('inputHighlight', {
  state: () => {
    return {
      isHighlight: false,
      buttonDisabled: false,
      inputValue: '' //header 输入框输入的值
    }
  }
})
