export default {
  mounted() {
    const imgs = document.getElementsByTagName('img')
    for (const img of imgs) {
      if (img.draggable) {
        img.draggable = false
      }
    }
  }
}
