import articleIconActiveSource from './article-icon-active.png'
import articleIconSource from './article-icon.png'
import collectIconActiveSource from './collect-icon-active.png'
import collectIconSource from './collect-icon.png'
import commentIconActiveSource from './comment-icon-active.png'
import commentIconSource from './comment-icon.png'
import consultIconActiveSource from './consult-icon-active.png'
import consultIconSource from './consult-icon.png'
import edit from './edit.png'
import goTopSource from './goTop.png'
import manImageSource from './man.jpg'
import empty from './noData.png'
import noticeIconActiveSource from './notice-icon-active.png'
import noticeIconSource from './notice-icon.png'
import share from './share.png'
import wechatScanImageSource from './wechat-scan.jpg'
import womanImageSource from './woman.jpg'
import logoSource from '/logo.webp'

export const logo = logoSource
export const articleIcon = articleIconSource
export const commentIcon = commentIconSource
export const collectIcon = collectIconSource
export const noticeIcon = noticeIconSource
export const consultIcon = consultIconSource
export const articleActiveIcon = articleIconActiveSource
export const commentActiveIcon = commentIconActiveSource
export const collectActiveIcon = collectIconActiveSource
export const noticeActiveIcon = noticeIconActiveSource
export const consultActiveIcon = consultIconActiveSource
export const wechatScanImage = wechatScanImageSource
export const manImage = manImageSource
export const womanImage = womanImageSource
export const goTopImg = goTopSource
export const shareImg = share
export const editImg = edit
export const emptyImg = empty
