/**
 * 防抖函数
 * @param func 要执行的函数
 * @param wait 等待时间（毫秒）
 * @param immediate 是否立即执行
 * @returns 返回防抖后的函数
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait?: number,
  immediate: boolean = false
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  return function (...args: Parameters<T>): void {
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }
    if (immediate && !timeoutId) {
      func(...args)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      if (!immediate) {
        func(...args)
      }
    }, wait || 500)
  }
}

/**
 * 节流函数
 * @param func 要执行的函数
 * @param wait 等待时间（毫秒）
 * @returns 返回节流后的函数
 */
export function throttle<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let lastCallTime = 0
  let timeoutId: ReturnType<typeof setTimeout> | null = null

  return function (...args: Parameters<T>): void {
    const now = Date.now()
    if (now - lastCallTime < wait) {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        lastCallTime = now
        func(...args)
      }, wait)
    } else {
      lastCallTime = now
      func(...args)
    }
  }
}

/**
 * 将像素值转换为 rem 单位
 * @param px - 像素值（可以是数字或带有单位的字符串）
 * @returns 转换后的 rem 字符串
 */
export function px2rem(px: string | number): string {
  const value = px.toString() // 确保 px 是字符串类型

  if (/%\b/.test(value)) {
    // 匹配以 % 结尾的情况
    return value // 直接返回原字符串
  } else {
    const num = parseFloat(value)
    if (isNaN(num)) {
      throw new Error('Invalid pixel value')
    }
    return num / 14.5 + 'rem' // 将像素值转换为 rem
  }
}

/**
 * 根据当前时间戳生成一个随机字符串
 *
 * 此函数通过结合时间戳和随机数来生成唯一字符串，常用于需要唯一标识符的场景
 * 使用时间戳可以确保字符串的唯一性，而随机数部分则提高了这一唯一性的程度
 *
 * @returns {string} 生成的随机字符串
 */
export function generateRandomStringByTimestamp(name: string): string {
  // 获取当前时间戳
  const timestamp = Date.now().toString()
  // 生成一个额外的随机数，增加字符串的随机性
  const randomPart = Math.random().toString(36).substring(2, 8)
  // 将时间戳转换为36进制字符串，并截取一部分
  const timestampPart = timestamp
    .split('')
    .map((char) => parseInt(char, 10))
    .map((num) => num.toString(36))
    .join('')
  // 结合时间戳部分和随机数部分
  const result = timestampPart.substring(0, 5) + randomPart

  // 分离文件名中的扩展名部分
  const [baseName, extension] = name.split('.').filter(Boolean)
  // 拼接上传入的name参数并保留扩展名
  const finalName = `${baseName}-${result}.${extension}`

  // 返回最终的字符串
  return finalName
}
