import type { CommonSelect } from '../assets/types/common'

const TOKEN_KEY = 'token'

export function createValueKey(arr: string[]): CommonSelect[] {
  const ret: any = []
  arr.forEach((item) => {
    ret.push({
      value: item,
      key: item
    })
  })
  return ret
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY)
}

export function setToken(data: string) {
  return localStorage.setItem(TOKEN_KEY, data)
}
