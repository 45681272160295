/**
 * 在新窗口中打开指定的URL
 * 如果未提供URL，则该函数不会执行任何操作
 * 如果提供了自定义URL，它将被直接打开
 * 如果提供了预定义的URL关键字，它将打开与该关键字关联的URL
 *
 * @param url 可选参数，要打开的URL字符串，可以是自定义的URL或预定义URL关键字
 */
export function openUrl(url?: string): void {
  if (!url) return

  // 定义一个枚举类，用于存储URL关键字和其对应的URL字符串
  interface UrlEnum {
    [key: string]: string
  }

  // 实例化UrlEnum，定义了关键字和URL的映射关系
  const enumeration: UrlEnum = {
    consult: 'https://www.editchecks.com.cn/CopyEditing.html' // 润色咨询的URL
  }

  // 根据传入的url参数打开相应的窗口
  window.open(enumeration[url] || url, '_blank')
}

// 校验用户是否在白名单中
export function examineWhiteList(): boolean {
  // 测试环境不校验
  if (import.meta.env.VITE_API_BASEURL === '/api') return true
  // 白订单用户列表
  const whiteList = [33, 39, 28, 1, 32, 44]
  if (localStorage.getItem('token') && localStorage.getItem('user')) {
    const { user } = JSON.parse(localStorage.getItem('user') as string)
    return whiteList.includes(user.userId)
  }
  return false
}
