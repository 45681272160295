import { defineStore } from 'pinia'
// 显示绑定邮箱弹窗显示
export const usebindEmailStore = defineStore('email', {
  state: () => {
    return {
      isShow: false, // 是否显示弹窗
      bindType: true // 绑定类型区别样式 //false用于编辑资料弹出
    }
  },
  actions: {
    changeModal(type = false) {
      this.bindType = type
      this.isShow = !this.isShow
      if (!this.isShow) {
        this.bindType = true
      }
    }
  }
})
