import router from '@/router'
import { loginByPhone, logout as logoutApi } from '@/services/auth'
import { getUserInfo } from '@/services/system'
import type { DefaultObject } from '@/types/common'
import { setToken } from '@/utils/common'
import { message } from 'ant-design-vue'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const isLogin = ref(false)
  const unreadCount = ref(0)
  const isUnreadCountLoading = ref(false)
  const onlyUser = ref<DefaultObject>({})
  const staticUser = ref<DefaultObject>({}) // 仅展示用户信息与编辑使用
  const userData: DefaultObject = ref({
    permissions: [],
    roles: [],
    user: {}
  })
  function fromStorageGetUser() {
    const userStorage = localStorage.getItem('user')
    if (userStorage) {
      const data = JSON.parse(userStorage)
      onlyUser.value = data.user
      staticUser.value = { ...data.user }
      userData.value = data
    }
  }
  function setUserStorage(storage: string) {
    localStorage.setItem('user', storage)
  }
  async function login(params: any) {
    const res = await loginByPhone(params)
    setToken(res.access_token)
    await handleUserInfo()
  }
  async function setUserInfo(data: any) {
    onlyUser.value = data.user
    staticUser.value = { ...data.user }
    userData.value = data
    isLogin.value = true
  }
  async function initStatic() {
    const waitMergeObject = {}
    Object.assign(waitMergeObject, {
      userOperateProfile: onlyUser.value.profile
    })
    staticUser.value = { ...onlyUser.value, ...waitMergeObject }
  }
  async function handleUserInfo(noFromStorageUpdate = false) {
    if (!noFromStorageUpdate) {
      fromStorageGetUser()
    }
    const res = await getUserInfo()
    const storage: DefaultObject = res
    // 设置默认头像
    if (storage.user.avatar) {
      storage.user.avatar = 'https://cdn.xuezhice.com' + storage.user.avatar
    }
    setUserInfo(storage)
    setUserStorage(JSON.stringify(storage))
    initStatic()
  }
  async function logout() {
    await logoutApi()
    resetUserData()
    message.success('已退出登录')
    router.push('/user/login')
  }
  async function resetUserData() {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('feedback_type')
    localStorage.removeItem('hiddenBind')
    userData.value = {
      permissions: [],
      roles: [],
      user: {}
    }
    isLogin.value = false
    initCacheEntry()
  }
  return {
    login,
    handleUserInfo,
    logout,
    isLogin,
    userData,
    onlyUser,
    unreadCount,
    resetUserData,
    isUnreadCountLoading,
    staticUser,
    initStatic
  }
})
