import type { DefaultObject } from '@/types/common'
import { get, post } from '@/utils/request'
import type { AxiosRequestConfig } from 'axios'

export const getUserInfo = async (data?: any) => {
  const response = await get('/system/user/getInfo', data)
  return response
}

export const getPreInfo = async (data?: AxiosRequestConfig) => {
  const response = await get('/system/user/getPreInfo', data)
  return response
}

export const modifyUserInfo = async (data?: DefaultObject) => {
  const response = await post('/system/user/modifyUserInfo', data)
  return response
}

export const getDisciplineFieldList = async (data?: AxiosRequestConfig) => {
  const response = await get('/info/journal/subjectArea', data)
  return response
}
// 上报错误
export const reportError = async (data?: DefaultObject) => {
  const response = await post('/system/report', data)
  return response
}
