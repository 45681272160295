import { useinputHighlightStore } from '@/store/inputHighlight'
import { nextTick, ref } from 'vue'

// 当前路由组件的缓存列表
const caches = ref<string[]>([])
// 用户还原缓存列表 重置使用
const cachesDataRef = ref<string[]>([])

/**
 * 钩子函数，用于管理路由组件的缓存
 * 它提供了添加缓存、移除缓存和重置缓存的功能
 */
export default function useRouteCache() {
  // 添加缓存的路由组件
  function addCache(componentName: string | string[]) {
    // 如果组件名称是一个数组，则递归调用addCache来缓存每个组件
    if (Array.isArray(componentName)) {
      componentName.forEach(addCache)
      return
    }
    // 指定不缓存的组件不加入缓存列表
    if (!componentName || caches.value.includes(componentName)) return

    // 将组件名称添加到缓存数组中
    caches.value.push(componentName)
    // 同时也将组件名称添加到缓存数据引用中
    cachesDataRef.value.push(componentName)
  }

  // 移除缓存的路由组件
  function removeCache(componentName: string) {
    // 查找组件名称在缓存数组中的索引
    const index = caches.value.indexOf(componentName)
    // 如果找到组件名称，则移除它
    if (index > -1) {
      return caches.value.splice(index, 1)
    }
  }

  // 移除缓存的路由组件的实例
  async function removeCacheEntry(componentName: string) {
    // 清除某个组件前最好在跳转前清除
    // 如果成功移除组件的缓存，则等待下一个Tick，然后重新缓存该组件
    if (removeCache(componentName)) {
      await nextTick()
      addCache(componentName)
    }
  }

  // 重置缓存
  async function initCacheEntry() {
    const inputStore = useinputHighlightStore()
    // 清除搜索栏
    inputStore.inputValue = ''
    // 清空缓存数组
    caches.value = []
    await nextTick()
    setTimeout(() => {
      caches.value = cachesDataRef.value
    }, 0)
  }

  // 返回缓存管理相关的数据和方法
  return {
    caches,
    addCache,
    removeCache,
    removeCacheEntry,
    initCacheEntry
  }
}
