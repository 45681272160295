import router from '@/router'
import { useUserStore } from '@/store/user'
import { Modal, message } from 'ant-design-vue'
import axios, { type AxiosRequestConfig, type InternalAxiosRequestConfig } from 'axios'
import CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import { tansParams } from './api-tool'
import { getToken } from './common'

const httpService = axios.create({
  baseURL: import.meta.env.VITE_API_BASEURL, // 设置API的基本URL
  timeout: 15000, // 设置超时时间
  headers: {
    'Content-Type': 'application/json'
  }
})

/**
 * 过滤对象，移除值为 null 或 undefined 的字段，并递归处理嵌套对象。
 * @param obj - 要过滤的对象
 * @returns 过滤后的对象
 */
function filterObject(obj: Record<string, any>): Record<string, any> {
  if (!obj) return {} // 如果对象不存在，返回空对象

  const filteredObj: Record<string, any> = {}

  for (const [key, value] of Object.entries(obj)) {
    if (value != null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
      if (typeof value === 'object' && !Array.isArray(value)) {
        // 递归处理嵌套对象
        const nestedFilteredObj = filterObject(value)
        if (Object.keys(nestedFilteredObj).length > 0) {
          filteredObj[key] = nestedFilteredObj // 如果嵌套对象不为空，保留该字段
        }
      } else {
        filteredObj[key] = value
      }
    }
  }

  return filteredObj
}

/**
 * 将对象转换为查询字符串，并按字段名的首字母排序。
 * @param obj - 要转换的对象
 * @returns 查询字符串
 */
function objectToQueryString(obj: Record<string, any>): string {
  const entries: string[] = []

  /**
   * 递归构建查询字符串的辅助函数。
   * @param obj - 当前处理的对象
   * @param prefix - 嵌套对象的前缀
   */
  function buildQueryString(obj: Record<string, any>, prefix?: string) {
    for (const [key, value] of Object.entries(obj)) {
      const fullKey = prefix ? `${prefix}[${key}]` : key
      if (Array.isArray(value)) {
        // 处理数组
        value.forEach((item, index) => {
          const arrayKey = `${fullKey}[${index}]`
          if (dayjs.isDayjs(item)) {
            // 处理日期
            entries.push(`${arrayKey}=${item.toDate().toUTCString()}`)
          } else if (typeof item === 'object' && !Array.isArray(item)) {
            // 递归处理嵌套对象
            buildQueryString(item, arrayKey)
          } else {
            // 将键值对添加到 entries 数组中
            entries.push(`${arrayKey}=${item}`)
          }
        })
      } else if (typeof value === 'object') {
        // 递归处理嵌套对象
        buildQueryString(value, fullKey)
      } else if (value instanceof Date || dayjs.isDayjs(value)) {
        // 处理日期
        entries.push(
          `${fullKey}=${dayjs.isDayjs(value) ? value.toDate().toUTCString() : value.toUTCString()}`
        )
      } else {
        // 将键值对添加到 entries 数组中
        entries.push(`${fullKey}=${value}`)
      }
    }
  }

  buildQueryString(obj)

  // 根据字段名的首字母排序
  entries.sort((a, b) => a.split('=')[0].localeCompare(b.split('=')[0]))

  return entries.join('&') // 将 entries 数组中的字符串用 & 拼接成最终的查询字符串
}
/**
 * 创建签名字符串
 * 该函数用于根据请求信息和headers生成一个签名字符串，通常用于安全验证或API调用的认证
 *
 * @param config 包含请求配置的对象
 * @param config.method 请求方法，如GET、POST等
 * @param config.url 请求URL
 * @param config.params 请求参数，为一个键值对对象
 * @param config.data 请求体数据，可选
 * @param config.headers 请求头，包含可选的Authorization字段，以及必需的t和nonce字段
 * @returns 返回签名字符串，由请求信息和headers组成的字符串，用于生成签名
 */
const createSign = (config: InternalAxiosRequestConfig): string => {
  // 解构赋值从config中提取方法、URL、参数、数据和headers
  const { method, url, params, data, headers } = config
  // 根据请求体数据生成数据部分的字符串，如果数据为空，则为空字符串
  const dataPart = data ? `${JSON.stringify(data)}` : ''
  // 根据Authorization头生成认证部分的字符串，如果Authorization头为空，则为空字符串
  const authPart = headers.Authorization ? `authorization:${headers.Authorization}` : ''
  // 根据请求参数生成排序后的查询参数字符串
  const filteredObj = filterObject(params)

  const paramsPart = objectToQueryString(filteredObj)
  // 请求方式、请求路径、请求参数、时间戳、随机数、token
  const parts = [
    method,
    url,
    paramsPart,
    dataPart,
    authPart,
    `t:${headers.t}`,
    `nonce:${headers.nonce}`
  ]
  // 过滤掉空的项
  const filteredParts = parts.filter((part) => part !== '')

  // 将所有部分用换行符连接起来，生成最终的签名字符串
  return filteredParts.join('\n')
}

// 添加请求拦截器
httpService.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 在发送请求之前做些什么
    // 可以在这里添加认证信息、loading状态等
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['t'] = new Date().getTime()
    config.headers['nonce'] = Math.random().toString(10).slice(-8)
    config.headers['source'] = 'pc'
    const sign = createSign(config)
    // SHA1加密
    config.headers['sign'] = CryptoJS.SHA1(sign).toString()

    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      }
      const requestSize = Object.keys(JSON.stringify(requestObj)).length // 请求数据大小
      const limitSize = 5 * 1024 * 1024 // 限制存放数据5M
      if (requestSize >= limitSize) {
        return config
      }
      const sessionObjStr = sessionStorage.getItem('sessionObj')
      if (sessionObjStr) {
        const sessionObj = JSON.parse(sessionObjStr)
        if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
          sessionStorage.setItem('sessionObj', JSON.stringify(requestObj))
        } else {
          const s_url = sessionObj.url // 请求地址
          const s_data = sessionObj.data // 请求数据
          const s_time = sessionObj.time // 请求时间
          const interval = 1000 // 间隔时间(ms)，小于此时间视为重复提交
          if (
            s_data === requestObj.data &&
            requestObj.time - s_time < interval &&
            s_url === requestObj.url
          ) {
            const message = '数据正在处理，请勿重复提交'

            return Promise.reject(new Error(message))
          } else {
            sessionStorage.setItem('sessionObj', JSON.stringify(requestObj))
          }
        }
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
httpService.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      if (
        response.request.responseType === 'blob' ||
        response.request.responseType === 'arraybuffer'
      ) {
        return response.data
      }
      const res = response.data
      const url = response.config.url
      const { code, data } = res
      if (code == 200) {
        if (data || data === false || data === 0) {
          return data
        }
        return res
      }
      if (res.code === 400 || res.code === 403) {
        message.error(res.msg)
        return Promise.reject(res.msg)
      }
      if (res.code === 401) {
        if (!getToken()) {
          Modal.destroyAll()
          Modal.confirm({
            title: '提示',
            content: '请先登录以继续使用服务',
            okText: '去登录',
            cancelText: '选择忽略',
            onOk() {
              router.push('/user/login')
            }
          })
        } else {
          useUserStore().resetUserData()
          const urlWhiteBlockList = ['/system/user/getInfo', '/info/message/unread']
          // url
          if (!urlWhiteBlockList.includes(url as string)) {
            Modal.destroyAll()
            Modal.confirm({
              title: '提示',
              content: '你的登录态已过期',
              okText: '去登录',
              cancelText: '选择忽略',
              onOk() {
                // window.location.href = '/user/login'
                router.push('/user/login')
              }
            })
          }
        }
        return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
      }
      if (res.code === 500) {
        return Promise.reject('服务器异常!')
      }
      return res
    }

    //网络请求状态码错误或者无权限等情况
  },
  (error) => {
    // 对响应错误做点什么

    let { message } = error
    if (message == 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }

    return Promise.reject(error)
  }
)

export function get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return httpService.get(url, config)
}

export function post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  return httpService.post(url, data, config)
}

export function put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
  return httpService.put(url, data, config)
}

export function deleted<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
  return httpService.delete(url, config)
}
