<template>
  <div>
    <div ref="bindMobileModalRef" class="component-bindMobileModal-container"></div>
    <a-modal
      footer=""
      :maskClosable="false"
      :closable="false"
      v-model:open="emailStore.isShow"
      :getContainer="() => $refs.bindMobileModalRef"
      :width="$px2rem(1080)"
    >
      <div class="flex-box">
        <div class="fx-1 left-box">
          <div class="desc-title">
            <i class="iconfont icon-gerenxinxi"></i>
            完善信息
          </div>
          <div class="desc-text">绑定邮箱有助于增强账户的安全防护，防止未经授权的访问。</div>
          <div class="tips">温馨提示</div>
          <div class="desc-list">· 一个邮箱只能绑定一个学之策账户，不能重复</div>
          <div class="desc-list">· 通过邮箱验证，您可以享受更个性化、更安全的服务体验</div>
          <div class="desc-list">· 邮箱是您找回账号的重要凭证，请务必绑定并妥善保管</div>
          <div class="desc-list">· 绑定邮箱后，您可以及时接收到我们的最新动态和重要通知</div>
          <div class="desc-list">
            · 通过简单的邮箱验证，我们可以更好地为您提供服务并保护您的权益
          </div>
          <div class="desc-list">
            · 请注意，未绑定邮箱可能会限制您对某些功能的使用，并增加账户风险
          </div>
          <div class="desc-list">· 我们承诺严格遵守隐私政策，不会泄露您的邮箱信息给第三方</div>
        </div>
        <div class="modal-inner-wrapper">
          <div class="operator-box">
            <div class="box-title">邮 箱 信 息</div>
            <div class="form-wrapper">
              <a-form :model="formState" @finish="handleFinish" ref="formRef">
                <a-form-item
                  :rules="[
                    { required: true, message: '请输入正确的邮箱号', trigger: 'blur' },
                    {
                      require: true,
                      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: '请输入正确的邮箱号',
                      trigger: 'blur'
                    }
                  ]"
                  name="email"
                >
                  <a-input
                    v-model:value="formState.email"
                    placeholder="请输入您的邮箱"
                    class="h-40"
                    autocomplete="off"
                  >
                    <template #prefix>
                      <!-- <div class="iconfont icon-shoujidenglu"></div> -->
                      <div class="iconfont icon-chufayoujian"></div>
                    </template>
                  </a-input>
                </a-form-item>
                <a-form-item
                  class="validate-code-box"
                  :rules="[
                    {
                      required: true,
                      message: '请输入正确的验证码',
                      trigger: 'blur',
                      pattern: /^\d{6}$/
                    }
                  ]"
                  name="code"
                >
                  <a-input-group compact>
                    <a-input
                      v-model:value="formState.code"
                      placeholder="请输入验证码"
                      class="h-40"
                      autoComplete="off"
                    >
                      <template #prefix>
                        <div class="iconfont icon-yanzhengma-"></div>
                      </template>
                    </a-input>
                    <a-button
                      type="primary"
                      :loading="validateCodeInterval.isLoading"
                      :disable="validateCodeInterval.isWait"
                      @click="sendApllicaionMessage"
                      class="width-102px h-40"
                      >{{
                        validateCodeInterval.isWait
                          ? validateCodeInterval.time + ' 秒'
                          : validateCodeInterval.isLoading
                            ? '正在发送'
                            : '发送验证码'
                      }}</a-button
                    >
                  </a-input-group>
                </a-form-item>
                <a-form-item class="button-wrapper">
                  <a-button
                    :loading="isSubmitLoading"
                    class="button"
                    type="primary"
                    html-type="submit"
                    >绑定
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
            <div class="skip" @click="hiddenModal">
              {{ emailStore.bindType ? '跳过，' : '' }}下次再完善
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script setup lang="ts">
defineOptions({ name: 'Bind-mail-index' })

import { modifyMail, sendMail } from '@/services/auth'
import { useUserStore } from '@/store/user'
import { message } from 'ant-design-vue'
import { reactive, ref, type UnwrapRef } from 'vue'

// 引入控制弹窗显示状态
import { usebindEmailStore } from '@/store/bindEmail'

const emailStore = usebindEmailStore()

interface FormState {
  email: string
  code: string
}
// 表单数据
const formState: UnwrapRef<FormState> = reactive({
  email: '',
  code: ''
})
// 倒计时参数
const validateCodeInterval = ref({
  time: 60,
  timer: 0,
  isWait: false,
  isLoading: false
})
// 表单节点
const formRef = ref()
// 提交加载中状态
const isSubmitLoading = ref(false)
/**
 * 异步发送应用消息函数
 *
 * 本函数用于发送应用内的消息，主要流程包括验证邮箱格式、发送邮件以及处理验证代码间隔逻辑
 * 在用户请求发送验证邮件时，会调用此函数以执行相关操作
 */
const sendApllicaionMessage = async () => {
  // 检查是否处于等待状态，如果是，则不执行任何操作
  if (validateCodeInterval.value.isWait) {
    return
  }
  // 设置正在加载状态为true，表示开始处理请求
  validateCodeInterval.value.isLoading = true
  try {
    // 验证邮箱字段，如果验证通过，则获取邮箱值
    const values = await formRef.value.validateFields(['email'])
    // 调用发送邮件函数，将验证通过的邮箱作为参数发送
    await sendMail({
      params: {
        email: values.email
      }
    })

    // 发送邮件成功后，更新状态为等待状态，并设置倒计时
    validateCodeInterval.value.isWait = true
    validateCodeInterval.value.timer = setInterval(() => {
      // 当倒计时为0时，重置倒计时和等待状态，并清除定时器
      if (validateCodeInterval.value.time === 0) {
        validateCodeInterval.value.time = 60
        validateCodeInterval.value.isWait = false
        clearInterval(validateCodeInterval.value.timer)
      } else {
        // 倒计时递减
        validateCodeInterval.value.time--
      }
    }, 1000)
    // 完成操作后，将加载状态设为false
    validateCodeInterval.value.isLoading = false
  } catch (e) {
    // 如果发生错误，将加载状态设为false
    validateCodeInterval.value.isLoading = false
  }
}
/**
 * 处理表单提交后的逻辑
 * @param {FormState} values - 表单提交的值
 */
const handleFinish = async (values: FormState) => {
  // 提交表单前，设置提交状态为加载中
  isSubmitLoading.value = true

  try {
    // 调用修改邮箱的API，传递表单提交的值
    await modifyMail(values)

    // 获取用户状态管理的store
    const store = useUserStore()
    // 从store中解构出更新用户信息的方法
    const { handleUserInfo } = store

    // 绑定邮箱成功后，显示成功消息
    message.success('绑定成功！')
    // 将提交状态重置为非加载中
    isSubmitLoading.value = false
    // 关闭邮箱绑定的弹窗
    emailStore.isShow = false
    // 将本地存储的一个标记设置为true，表示已绑定邮箱
    localStorage.setItem('hiddenBind', 'true')
    // 调用更新用户信息的方法
    if (emailStore.bindType) {
      await handleUserInfo()
    } else {
      store.staticUser.email = values.email
      store.userData.user.email = values.email
    }
  } catch (e) {
    // 如果提交过程中发生错误，将提交状态重置为非加载中
    isSubmitLoading.value = false
  }
}

/**
 * 隐藏模态框并记录绑定状态
 *
 * 此函数用于隐藏电子邮件相关的模态框，并在本地存储中记录绑定状态为 true
 * 它通过调用 emailStore 中的 changeModal 方法来控制模态框的显示与隐藏
 * 同时，将 'hiddenBind' 键存储到本地存储中，用于后续判断模态框是否被隐藏
 */
const hiddenModal = () => {
  // const store = useUserStore()
  // store.staticUser.email = 'values.email'

  emailStore.changeModal() // 调用 emailStore 的 changeModal 方法来改变模态框的显示状态
  localStorage.setItem('hiddenBind', 'true') // 将 'hiddenBind' 键和 'true' 值存储到本地存储中，表示模态框后续不显示
}
</script>

<style lang="less" scoped>
.component-bindMobileModal-container {
  .icon-chufayoujian {
    font-size: 18px;
    color: #999;
    padding-right: 6px;
  }
  .left-box {
    box-sizing: border-box;
    padding-left: 22px;
  }
  .skip {
    color: #18337b;
    text-align: center;
    cursor: pointer;
    padding-top: 10px;
  }
  .desc-list {
    font-size: 14px;
    padding-left: 10px;
    line-height: 28px;
    color: #454545;
    color: #666;
  }
  .tips {
    font-size: 18px;
    font-weight: 400;
    color: #454545;
    padding-top: 10px;
  }
  .desc-text {
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    &::after {
      content: '';
      width: 300px;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgb(186, 204, 240);
      background: linear-gradient(90deg, rgba(186, 204, 240, 1) 0%, rgba(255, 255, 255, 1) 96%);
    }
  }
  .desc-title {
    font-size: 24px;
    font-weight: bold;
    i {
      font-size: 34px;
      color: #163273;
      position: relative;
      top: 3px;
      margin-right: 20px;
    }
  }
  .modal-inner-wrapper {
    // height: 300px;
    display: flex;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: -60px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 200px;
      background: #2c60d94d;
    }
    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 240px;
      border-right: 1px solid #ececec;
      height: 100%;
      .icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 1px solid #ececec;
        .iconfont {
          font-size: 32px;
          transform: rotate(270deg);
        }
      }
    }
    .icon-shoujidenglu {
      font-size: 28px;
      color: #999;
      margin-left: -4px;
    }
    .icon-yanzhengma- {
      font-size: 24px;
      color: #999;
      position: relative;
      left: -2px;
    }
    .h-40 {
      height: 40px;
      box-shadow: none !important;
    }
    .operator-box {
      flex: 1;
      padding-top: 60px;
      .box-title {
        display: flex;
        align-items: center;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
        font-size: 22px;
        justify-content: center;
        margin-right: 10px;
        padding-bottom: 30px;
        .icon-dianhua {
          font-size: 24px;
          margin-right: 18px;
        }
      }
      .title-desc {
        display: flex;
        justify-content: center;
        padding: 20px 0 10px 0;
        font-size: 12px;
        .brand {
          color: @primary-color;
          display: inline-block;
        }
        .highlight {
          color: rgb(255, 0, 0);
          display: inline-block;
        }
      }

      .form-wrapper {
        padding: 0 40px 20px 40px;
        position: relative;
        &::after {
          content: '';
          width: 400px;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 2%,
            rgba(192, 208, 244, 1) 53%,
            rgba(252, 252, 252, 1) 98%
          );
          position: absolute;
          bottom: 0;
        }
      }
      .validate-code-box {
        .ant-input-group.ant-input-group-compact {
          display: flex;
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        .button {
          width: 150px;
        }
      }
    }
  }
}
</style>
