// 引入Vue Router相关函数
import { createRouter, createWebHistory } from 'vue-router'
// 引入路由缓存钩子
import useRouteCache from '../hooks/useRouteCache'
// 引入路由缓存排除规则
import { excludeRule } from '../router/keepAliveRule'
// 引入重定向路径存储
import { useRedirectStore } from '../store/redirectPath'
// 导入用户状态管理store
import { useUserStore } from '@/store/user'
// 引入控制弹窗显示状态
import { usebindEmailStore } from '@/store/bindEmail'
// 导入输入header输入框状态管理store
import { useinputHighlightStore } from '@/store/inputHighlight'

// 添加缓存的函数
const { addCache } = useRouteCache()

// 动态导入所有视图组件
const modules = import.meta.glob('../views/**/*.vue')

// 用于存储组件名称的数组
const componentNameArr = []

// 用于存储路由信息的数组
const routeList = []
// 遍历modules对象，获取每个视图组件的路由路径和组件名称
for (const key in modules) {
  let routePath = key.replace('../views', '').replace('.vue', '')
  // 清除第一个 / 并将第二个 / 替换成 -
  const componentName = routePath.replace('/', '').replace('/', '-')
  // 确保组件名称有效且不在排除规则列表中，然后将其添加到组件名称数组
  if (componentName && !excludeRule.includes(componentName)) {
    componentNameArr.push(componentName)
  }
  // 对于/home/index路径，简化为根路径'/'
  routePath = routePath === '/home/index' ? '/' : routePath

  // 创建路由对象并添加到路由列表中
  const obj = {
    path: routePath,
    component: modules[key]
  }
  routeList.push(obj)
}

// 将有效组件名称数组添加到缓存
addCache(componentNameArr as string[])

// 创建路由实例，配置路由历史模式和路由列表
const router = createRouter({
  history: createWebHistory(),
  routes: routeList
})

// 设置全局前置守卫
router.beforeEach((to, from, next) => {
  //清除header输入框输入的值
  if (to.path === '/selectivePeriodical/selectivePeriodical') {
    const inputStore = useinputHighlightStore()
    inputStore.inputValue = ''
  }
  // 跳转登录记录页面方便返回
  if (to.path === '/user/login') {
    const store = useRedirectStore()
    // 以下路径跳转登录成功后返回首页
    const specialPaths = ['/user/forgetPassword', '/user/personal-edit', '/user/register']
    const redirectPath = !specialPaths.includes(from.path) ? from.fullPath : '/'
    // 保存返回路径
    store.setRedirectPath(redirectPath)
  }
  // 使用用户状态管理 store，用于管理用户相关的状态
  const userStore = useUserStore()
  //  用户第一次登录且未绑定邮箱弹出绑定邮箱
  if (userStore.isLogin && !userStore.userData.user.email && !localStorage.getItem('hiddenBind')) {
    const emailStore = usebindEmailStore()
    emailStore.changeModal()
  }
  // 用户未登录 不允许进入期刊详情 与国自然详情
  // if (!getToken() &&(to.path === '/search/practical-info' || to.path === '/search/country-nature-info')) {
  //   Modal.confirm({
  //     title: '提示',
  //     content: '请登录后操作',
  //     okText: '去登录',
  //     cancelText: '选择忽略',
  //     onOk() {
  //       router.push('/user/login')
  //     }
  //   })
  //   return
  // }
  next()
})

router.afterEach((to, from) => {
  // 每次跳转时，自动滚动到页面顶部
  window.scrollTo(0, 0)
})
// 导出默认的路由实例
export default router
