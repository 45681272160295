<script setup lang="ts">
import variables from '@/assets/variables.module.less'
import bindEmail from '@/components/Bind-mail/index.vue'
import bindMobile from '@/components/Bind-mobile-modal/index.vue'
import { examineWhiteList } from '@/hooks/useTool'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import DisableDevtool from 'disable-devtool'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import useRouteCache from './hooks/useRouteCache'
import { useResourceStore } from './store/resource'
import { useUserStore } from './store/user'
import { getToken } from './utils/common'
import transform from './utils/px2Rem'

const { caches } = useRouteCache()

const $route = useRoute()

onMounted(() => {
  if (getToken()) {
    useUserStore().handleUserInfo()
  }
  useResourceStore().getDisciplineFieldList()
})
const getBeforeHash = (str: string): string => {
  const hashIndex = str.indexOf('#')
  if (hashIndex !== -1) {
    return str.substring(0, hashIndex)
  }

  return str
}
const px2rem = transform({
  rootValue: 14.5
})

function setRem() {
  const baseSize = 14.5 // 根元素的基准大小
  const designWidth = 1920 // 设计稿的宽度
  const clientWidth = document.documentElement.clientWidth
  const scale = clientWidth / designWidth // 比例
  document.documentElement.style.fontSize = baseSize * scale + 'px'
}

window.addEventListener('resize', setRem)
setRem()

// 检测是否是 Windows 系统
if (navigator.userAgent.includes('Windows')) {
  document.body.classList.add('is-windows')
}
interface IConfig {
  md5?: string // 绕过禁用的md5值，详情见3.2，默认不启用绕过禁用
  url?: string // 关闭页面失败时的跳转页面，默认值为localhost
  tkName?: string // 绕过禁用时的url参数名称，默认为 ddtk
  ondevtoolopen?(type: DetectorType, next: Function): void // 开发者面板打开的回调，启用时url参数无效，type 为监测模式，详见3.5， next函数是关闭当前窗口
  ondevtoolclose?(): void // 开发者面板关闭的回调
  interval?: number // 定时器的时间间隔 默认200ms
  disableMenu?: boolean // 是否禁用右键菜单 默认为true
  stopIntervalTime?: number // 在移动端时取消监视的等待时长
  clearIntervalWhenDevOpenTrigger?: boolean // 是否在触发之后停止监控 默认为false， 在使用ondevtoolclose时该参数无效
  detectors?: Array<DetectorType> // 启用的检测器 检测器详情见 3.5 默认为全部，建议使用全部
  clearLog?: boolean // 是否每次都清除log
  disableSelect?: boolean // 是否禁用选择文本 默认为false
  disableCopy?: boolean // 是否禁用复制 默认为false
  disableCut?: boolean // 是否禁用剪切 默认为false
  disablePaste: boolean // 是否禁用粘贴 默认为false
  ignore?: (string | RegExp)[] | null | (() => boolean) // 某些情况忽略禁用
  disableIframeParents?: boolean // iframe中是否禁用所有父窗口
  timeOutUrl?: string // 关闭页面超时跳转的url;
  rewriteHTML: string // 检测到打开之后重写页面
}

enum DetectorType {
  Unknown = -1,
  RegToString = 0, // 根据正则检测
  DefineId, // 根据dom id检测
  Size, // 根据窗口尺寸检测
  DateToString, // 根据Date.toString 检测
  FuncToString, // 根据Function.toString 检测
  Debugger, // 根据断点检测，仅在ios chrome 真机情况下有效
  Performance, // 根据log大数据性能检测
  DebugLib // 检测第三方调试工具 eruda 和 vconsole
}

// 禁止调试， 只在生产环境下开启
DisableDevtool({
  md5: '4a9713424055227704bf1f1c539b406f',
  disableMenu: false,
  ignore: () => {
    return examineWhiteList() // 当是测试环境或者白名单忽略禁用
  },
  ondevtoolopen: () => {
    window.location.href = 'about:blank'
  },
  rewriteHTML: '暂未开放调试模式' //如已打开调试则重写页面内容
})

// const key = DisableDevtool.md5('XueZhiCe1992')
</script>

<template>
  <a-config-provider
    :theme="{
      token: {
        ...variables
      }
    }"
    :locale="zhCN"
  >
    <a-style-provider :transformers="[px2rem]">
      <RouterView v-slot="{ Component }">
        <keep-alive :include="caches">
          <component :is="Component" :key="getBeforeHash($route.fullPath)" />
        </keep-alive>
      </RouterView>
    </a-style-provider>
    <!-- 绑定邮箱弹窗 -->
    <bind-email />
    <!-- 绑定号码弹窗 -->
    <bind-mobile />
  </a-config-provider>
</template>

<style scoped></style>
