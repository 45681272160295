<template>
  <div class="global-footer">
    <div class="footer-main">
      <div class="main-left">
        <div class="main-left-inter">
          <div class="box">
            <div class="logo">
              <img :src="logo" alt="" />
            </div>
          </div>
          <div class="keywords">
            <div class="keyword-box">
              <div>自由</div>
              <div class="mark">探索</div>
              <div>知识</div>
            </div>
            <div class="keyword-box">
              <div>开拓</div>
              <div class="mark">无限</div>
              <div>可能</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-center">
        <div class="part">
          <div class="part-content">
            <div class="cell" @click="openUrl('/search/index')">期刊查询</div>
            <div class="cell" @click="openUrl('/search/index?tab=2')">文献查询</div>
            <div class="cell" @click="openUrl('/selectivePeriodical/selectivePeriodical')">
              投稿选刊
            </div>
          </div>
        </div>
        <div class="part">
          <div class="part-content">
            <div class="cell" @click="openUrl('/search/index?tab=4')">基金查询</div>
            <div class="cell" @click="openUrl('/practical-tools/index')">科研工具</div>
            <div class="no-link-cell">
              <a-tooltip placement="top">
                <template #title>
                  <span>敬请期待</span>
                </template>
                期刊对比
              </a-tooltip>
            </div>
          </div>
        </div>
        <div class="gray-part has-border-box">
          <div class="gray-part-content">
            <div class="cell" @click="openUrl('/about/index')">关于学之策</div>
            <div class="cell" @click="openUrl('/about/term-service')">服务条款</div>
            <div class="cell" @click="openUrl('/about/privary-agreement')">隐私协议</div>
          </div>
        </div>
        <div class="gray-part">
          <div class="gray-part-content">
            <div class="cell domain" @click="openUrl('https://beian.miit.gov.cn')">
              皖ICP备2024032481号-1
            </div>
          </div>
        </div>
      </div>
      <div class="main-right">
        <div class="new-scan-code">
          <img class="scan-code-img" :src="wechatScanImage" alt="" />
          <div class="text">扫一扫,关注我们</div>
        </div>
        <!-- <div class="icon-group">
          <a-popover placement="right" overlayClassName="component-global-footer-popover-container">
            <template #content>
              <div class="scan-code">
                <img class="scan-code-img" :src="wechatScanImage" alt="" />
                <div class="text" style="color: #fff; text-align: center">扫一扫，关注我们</div>
              </div>
            </template>
            <div class="icon-box">
              <img :src="scanIcon" alt="" />
            </div>
          </a-popover>
          <a-tooltip placement="left">
            <template #title>
              <span>客服邮箱</span>
            </template>
            <a href="mailto: support@xuezhice.com" class="icon-box" target="_blank">
              <img :src="mailIcon" alt="" />
            </a>
          </a-tooltip>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
defineOptions({ name: 'Footer-index' })

import { logo, wechatScanImage } from '@/assets/images/common'
const openUrl = (url: string) => {
  window.open(url)
}
</script>

<style lang="less">
.component-global-footer-popover-container {
  .ant-popover-arrow {
    top: 34%;
  }
  .ant-popover-inner {
    background: rgba(0, 0, 0, 0.1);
    width: 180px;
    margin-top: 70px;

    .scan-code {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .scan-code-img {
        width: 150px;
        height: 150px;
      }
      .text {
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
@footer-primary-color: #110863;

.global-footer {
  letter-spacing: 3px;
  .new-scan-code {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    position: relative;
    flex-direction: column;
    padding: 10px;
    &::after {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      border-width: 4px 4px 0 0;
      border-style: solid;
      border-color: #0c22ad;
      right: 0;
      top: 0;
    }
    &::before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      border-width: 0 0 4px 4px;
      border-style: solid;
      border-color: #0c22ad;
      left: 0;
      bottom: 0;
    }
    img {
      width: 130px;
      height: 130px;
    }
    .text {
      position: absolute;
      left: 50%;
      bottom: -22px;
      width: 100%;
      text-align: center;
      transform: translateX(-50%);
      font-size: 12px;
      padding-top: 10px;
      color: #999999;
    }
  }
  .footer-main {
    display: flex;
    align-items: center;
    padding: 20px 80px 20px 84px;
    background: #e5eaf5;
    .main-left-inter {
      flex: 1;
      display: flex;
      // flex-direction: column;
      // align-items: center;
      .box {
        display: flex;
        align-items: center;
        .logo {
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .logo-name {
          font-size: 18px;
          width: 18px;
          margin-left: 20px;
          color: @footer-primary-color;
        }
      }
      .keywords {
        margin-left: 20px;
        :first-child {
          .mark {
            color: @primary-color;
          }
        }
        :last-child {
          .mark {
            color: #ad4843;
          }
        }
        .keyword-box {
          display: flex;
          align-items: center;
          margin: 0 10px;
          letter-spacing: 0;
          * {
            font-weight: bold;
          }
          .mark {
            margin: 0 8px 8px;
            font-family: slideyouran;
            font-size: 28px;
            letter-spacing: 0;
          }
        }
      }
    }
    .main-center {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 558px;
      margin-left: 530px;
      .part {
        width: 100%;
        .part-content {
          font-size: 16px;
          margin-top: 16px;
          display: flex;
          flex-wrap: wrap;
          .cell {
            width: 146px;
            line-height: 26px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
              color: @primary-color;
              text-decoration: underline;
            }
            a {
              color: #000;
              letter-spacing: 0;
            }
          }
          .no-link-cell {
            width: 146px;
            line-height: 26px;
            display: flex;
            align-items: center;
          }
        }
      }
      .has-border-box {
        border-top: 1px solid rgb(205, 200, 200);
        margin-top: 16px;
        padding-top: 16px !important;
      }
      .gray-part {
        width: 100%;
        padding-top: 8px;
        .gray-part-content {
          display: flex;
          flex-wrap: wrap;
          .cell {
            min-width: 146px;
            line-height: 26px;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: rgb(153, 153, 153);
            font-size: 15px;
            &:hover {
              color: @primary-color;
              text-decoration: underline;
            }
          }
          .domain {
            letter-spacing: 0;
          }
          a {
            color: rgba(205, 200, 200, 1);
          }
        }
      }
    }
    .main-right {
      display: flex;
      align-items: center;
      margin-left: auto;
      .scan-code {
        width: 180px;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        margin-right: 16px;
        position: relative;
        opacity: 0;
        .xzc-box-shadow;
        img {
          width: 100%;
          height: 100%;
        }
        .text {
        }
      }
      .code-text {
        color: #000;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 15px;
      }
      .icon-group {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .icon-box {
        border: 1px solid rgb(153, 153, 153);
        border-radius: 50%;
        padding: 10px;
        margin-bottom: 20px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
        &:hover {
          border: 1px solid @primary-color;
        }
      }
    }
  }
  .footer-bottom {
    height: 40px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    background: #1d316f;
    padding: 0 10%;
  }
}
</style>
