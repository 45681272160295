import { getDisciplineFieldList as apiGetDisciplineFieldList } from '@/services/system'
import type { DefaultObject } from '@/types/common'
import { defineStore } from 'pinia'
import { ref } from 'vue'

interface CommonItem {
  value: string
  label: string
}

export const useResourceStore = defineStore('resource', () => {
  const departmentList = ref([])
  const subjectAreaList = ref<CommonItem[]>([])
  const getDisciplineFieldList = async () => {
    const res: any = await apiGetDisciplineFieldList()
    subjectAreaList.value = res.map((item: DefaultObject) => ({
      value: '' + item.id,
      label: item.name
    }))
  }
  const baseIdQuerySubjectAreaItem = (id: string): CommonItem | undefined => {
    const result: CommonItem | undefined = subjectAreaList.value.find(
      (item: DefaultObject) => item.value === id
    )
    return result
  }
  return {
    departmentList,
    subjectAreaList,
    getDisciplineFieldList,
    baseIdQuerySubjectAreaItem
  }
})
