import { deleted, get, post, put } from '@/utils/request'
import type { AxiosRequestConfig } from 'axios'

export const createQrcode = async (data?: AxiosRequestConfig) => {
  const response = await get('/auth/qrcode', data)
  return response
}

export const getLoginRecycle = async (ticket: string) => {
  const response = await get(`/auth/loginRecycle/${ticket}`)
  return response
}
export const sendMessage = async (data?: AxiosRequestConfig) => {
  const response = await get('/auth/sendMessage', data)
  return response
}
export const loginByPhone = async (data: any) => {
  const response = await post('/auth/loginByPhone', data)
  return response
}

export const modifyPhoneNumber = async (data: any) => {
  const response = await post('/auth/modifyPhoneNumber', data)
  return response
}

export const register = async (data: any) => {
  const response = await post('/auth/register', data)
  return response
}

export const forget = async (data: any) => {
  const response = await post('/auth/forget', data)
  return response
}

export const logout = async (data?: any) => {
  const response = await deleted('/auth/logout', data)
  return response
}
export const changePwd = async (data: any) => {
  const response = await put('/auth/changePwd', data)
  return response
}

export const verifyCode = async (data?: AxiosRequestConfig) => {
  const response = await get('/auth/verifyCode', data)
  return response
}

export const sendMail = async (data?: any) => {
  const response = await get('/system/user/sendMail', data)
  return response
}

export const modifyMail = async (data?: any) => {
  const response = await post('/system/user/modify', data)
  return response
}

export const verifyEmailCode = async (data?: AxiosRequestConfig) => {
  const response = await get('/system/user/verifyCode', data)
  return response
}
